var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popup',{ref:"pop",scopedSlots:_vm._u([{key:"popContent",fn:function(){return [_c('div',{staticClass:"invoiceMsgContent",style:(_vm.pageStyle)},[_c('div',{staticClass:"editOrderBox"},[_c('iHeader',{attrs:{"backType":"blank","headStyle":['msd', 'dsm'].includes(_vm.$cookies.get('tenant'))
              ? {
                  background: '#fff',
                  color: '#13161B',
                }
              : {
                  background: 'var(--themeColor)',
                  color: '#fff',
                },"backEvent":true,"back":true},on:{"back-event":function($event){return _vm.openPop()}}},[_c('div',{attrs:{"slot":"headText"},slot:"headText"},[_c('span',{staticClass:"head-txt"},[_vm._v(" "+_vm._s(["pfizer"].includes(_vm.$cookies.get("tenant")) ? "租车需求" : "城市车队需求")+" ")])])]),_c('div',{staticClass:"editOrderContent"},[_c('div',[_c('Tips',{attrs:{"titile":"注意事项","tips":_vm.tips,"val":'val'}}),_c('div',{staticClass:"imgBoxContent"},[_c('p',{staticClass:"title"},[_vm._v("费用说明")]),_c('p',{staticClass:"titleShow"},[_vm._v("车队行程报价说明")]),_c('img',{attrs:{"src":_vm.$cookies.get('tenant') == 'msd'
                    ? 'https://spkg.smartmice.cn/resources/images/icar/priceDescription.jpg'
                    : 'https://spkg.smartmice.cn/resources/images/icar/priceDescription_pfizer.jpg',"preview":"1"}}),_c('p',{staticClass:"titleShow"},[_vm._v("车队订单取消违约金说明")]),_c('img',{attrs:{"src":_vm.$cookies.get('tenant') == 'msd'
                    ? 'https://spkg.smartmice.cn/resources/images/icar/canselDescription.jpg'
                    : 'https://spkg.smartmice.cn/resources/images/icar/canselDescription_pfizer.jpg',"preview":"1"}})]),_c('div',{staticClass:"backBtn",on:{"click":_vm.openPop}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconright"}})])])],1)])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }