<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-11-30 17:13:04
-->
<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <iHeader backType="blank" :backEvent="true" @back-event="supplierDetailback()" :back="true">
      <div slot="headText" v-if="rebackTitle != '拒绝'">
        <span class="head-txt">
          申请退回重报
        </span>
      </div>
    </iHeader>
    <div class="refuseBox">
      <p class="refuseTitle">{{ rebackTitle }}原因</p>
      <div class="refuseRBox">
        <textarea placeholder="请填写原因" class="choseInput" v-model="rebackDescr"></textarea>
      </div>
      <a class="saveBtn" @click="sureBtn">保存</a>
    </div>
  </mt-popup>
</template>

<script>
export default {
  props: {
    PerformTask: {
      type: Function
    }
  },
  data() {
    return {
      isShowPopup: false,
      rebackDescr: '',
      rebackTitle: ''
    };
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners('supplierDetailback'); //保存当前路由返回事件记录
        this.$root.$eventHub.$on('supplierDetailback', data => {
          this.supplierDetailback();
          this.$root.$eventHub.$off('supplierDetailback');
        });
      } else {
        this.removeRouteListeners('supplierDetailback'); //返回删除记录
      }
    }
  },
  methods: {
    showPopup(data, rebackTitle) {
      this.rebackDescr = data;
      this.isShowPopup = true;
      this.rebackTitle = rebackTitle || '拒绝';
    },
    supplierDetailback() {
      this.isShowPopup = false;
    },
    sureBtn() {
      this.$parent.rebackDescr = this.rebackDescr;
      if (this.rebackTitle == '拒绝') {
        if (!this.rebackDescr) {
          this.$toast(`请填写必填信息`);
          return;
        }
      }
      this.PerformTask();
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/common.scss';
.popup_box {
  top: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  background: #fff;
  position: fixed;
}
</style>
