<template>
  <div class="adressBox">
    <div>
      <div class="addressMain">
        <span>{{ item.itsExpenseData && item.itsExpenseData.sDateTime && item.itsExpenseData.sDateTime.substring(item.itsExpenseData.sDateTime.length - 5) }}</span>
        <p>
          {{ item.itsExpenseData && item.itsExpenseData.sDateTime && item.itsExpenseData.sDateTime.substring(0, 10) }}
        </p>
      </div>
      <div class="addressIcon">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div class="addressMain rightAdress">
        <span>{{ item.itsExpenseData && item.itsExpenseData.eDateTime && item.itsExpenseData.eDateTime.substring(item.itsExpenseData.eDateTime.length - 5) }}</span>
        <p>
          {{ item.itsExpenseData && item.itsExpenseData.eDateTime && item.itsExpenseData.eDateTime.substring(0, 10) }}
        </p>
      </div>
    </div>
    <div v-if="isShowDetail" class="info_box">
      <div>{{ item.itsExpenseData.carModus }}</div>
      <div>{{ item.itsExpenseData.pieceQty }}人</div>
    </div>

    <div class="address_info" v-if="isShowDetail">
      <div>
        <i class="greenBc"></i>
        {{ item.itsExpenseData && item.itsExpenseData.departure }}
      </div>
      <div>
        <i class="orangeBc"></i>
        {{ item.itsExpenseData && item.itsExpenseData.destination }}
      </div>
    </div>
    <div class="infoBox" v-if="isShowDetail">
      <div class="escarNum" v-if="$cookies.get('tenant') == 'msd'">
        EasyCarNO：
        <span>{{ item.easyCarNo || '暂无' }}</span>
      </div>
    </div>
    <!-- 展示司机信息 -->
    <!-- <div v-if="isShowDetail&&driverData" class="currentDriver">
      <div>司机信息:{{ driverData.name }} ({{driverData.phone}} )</div>
      <div>车辆信息:{{driverData.carModel}} {{driverData.licensePlate}}</div>
    </div> -->

    <div class="expand_detail" v-if="isShowDetail">
      <div class="moneyBox">
        {{ item.money || item.amount | currencyFormatter }}
      </div>
      <div
        @click.stop="item.isShow = !item.isShow"
        class="show_detail"
        v-if="isShowDetail && ($route.query.isApproval || (orderData.status == 60 && ['msd'].includes($cookies.get('tenant')) && !SmartStorage.get('viewOnly')))"
      >
        {{ item.isShow ? '收起详情' : '展开详情' }}
        <svg class="icon icon-normal" aria-hidden="true" :class="item.isShow ? 'rotateDown' : ''">
          <use xlink:href="#icon-jiantouxia" />
          <!-- <use v-show="item.isShow" xlink:href="#icon-jiantoushang" /> -->
        </svg>
      </div>
      <template v-if="isShowDetail">
        <div :class="['', btn.status == 1 || (btn.status == 3 && 'GraeBtn')]" v-for="(btn, index) in item.buttons" :key="index" @click.stop="goSurvayPage(item, btn)">
          {{ btn.Txt }}
        </div>
      </template>
    </div>

    <!-- 实际乘车人  -->
    <div v-if="isShowDetail" class="passengersBox_container">
      <div class="passengersBox">
        <template v-if="item.isShow">
          <template v-if="item.orderPassenger.length > 0">
            <div class="passengers_list">
              <div class="passengers_title">
                <div>实际乘车人:</div>
                <div v-for="(passenger, pindex) in item.orderPassenger" :key="pindex" class="passengersLi">
                  <span class="name">{{ passenger.passengers }}</span>
                  <br />
                  <span v-if="!['msd'].includes($cookies.get('tenant'))">{{ passenger.organization }}</span>
                  <span>{{ passenger.department }}</span>
                </div>
              </div>
              <div class="edit_passager" v-if="SmartStorage.get('orderDetail') ? [0, 1, 4].includes(SmartStorage.get('orderDetail').Status) : true">
                <a @click.stop="goPassenger(item)">修改实际乘车人</a>
              </div>
            </div>
          </template>
          <div v-else class="add_passager">
            <div>
              暂无实际乘车人数据，请添加
            </div>
            <div @click.stop="goPassenger(item)" v-if="SmartStorage.get('orderDetail') ? [0, 1, 4].includes(SmartStorage.get('orderDetail').Status) : true" class="edit_passager">
              修改实际乘车人
            </div>
          </div>
          <template v-if="!['msd'].includes($cookies.get('tenant'))">
            <div>用车备注:</div>
            <p>
              {{ (item.orderPassenger[0] && item.orderPassenger[0].remark) || '暂无' }}
            </p>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    },
    isShowDetail: {
      type: Boolean
    },
    goPassenger: {
      type: Function
    },
    orderData: {
      type: Object
    },
    goSurvayPage: {
      type: Function
    }
  },
  data() {
    return {
      detail: '展开详情',
      driverData: {}
    };
  },
  mounted() {
    // this.getCurrentDriver();
  },
  methods: {
    toDetail() {
      // this.$emit('toDetail',this.item)
      this.detail = this.detail === '展开详情' ? '收起详情' : '展开详情';
    },
    getCurrentDriver() {
      if (this.orderData.orderId) {
        let params = {
          orderId: this.orderData.orderId
        };
        this.services.currentDrivers(params).then(res => {
          console.log('res===', res);
          this.driverData=res.content;
          // console.log('driverData',this.driverData);
        });
      }
    }
  }
};
</script>
