<template>
  <div class="contentBox">
    <h6>
      <img class="carImg" :src="orderData.supplierThumbnail" v-if="orderData.supplierThumbnail" />
        <svg class="icon icon-normal" aria-hidden="true" v-else>
      <use xlink:href="#icon-zhongzhigongyingshangcheduiLOGO" />
    </svg>
      {{ orderData.itsExtData.supplierName}}
    </h6>
    <p class="priceBox">
      <span>报价</span>
      <i>￥{{ orderData.quoteTotalAmount }}</i>
    </p>
    <p class="priceBox">
      <span>结算</span>
      <i>￥{{ orderData.totalAmount }}</i>
    </p>
    <p class="questionBox" @click.stop="openTipsPage()" v-if="orderData.status==-2">
      费用疑问<svg class="icon" aria-hidden="true">
        <use xlink:href="#iconiconfontquestion" />
      </svg>
    </p>
    
  </div>
</template>
<script>

export default {
  props: {
    orderData: {
      type: Object,
    },
    openTipsPage:{
      type:Function
    }
  },
  created() {
    console.log('orderData==',this.orderData)
  },
  methods: {
    goDetailPage(data) {
      this.$router.push({
        path: "/carDetail",
        query: {
          noShowFoot: true,
        },
      });
    },
    
  },
};
</script>