<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-14 11:49:16
-->
<template>
  <div :class="['infoMain', $route.query.isApproval && 'infoMainApproval']">
    <div class="piecesMianContent">
      <timeAddressPiece :item="item" :isShowDetail="true" :goPassenger="goPassenger" :orderData="orderData" :goSurvayPage="goSurvayPage"></timeAddressPiece>
    </div>
  </div>
</template>
<script>
import timeAddressPiece from "@/views/common/tenant/timeAddressPiece";
export default {
  name:'accountExpensePiecesInfo',
  props: ["item", "goSurvayPage", "passengersList", "isShowDetail","goPassenger","orderData"],
  components: {
    timeAddressPiece,
  },
};
</script>
<style lang="sass" scoped></style>
