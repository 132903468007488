<template>
  <div class="accountBox">
    <div class="publicHeadClass">
      <iHeader backType="blank" :backEvent="true" @back-event="carAccountGoBack()" :back="true">
        <div slot="headText" class="isScroll" v-if="orderData.status">
          {{ orderData.status == 100 ? '已完成' : orderData.status == -2 ? '取消有损失' : '确认结算' }}
        </div>
        <div slot="rightBtn" @click="goDetailPage(orderData)">
          <span class="rightBtn">查看需求</span>
        </div>
      </iHeader>
    </div>
    <div class="supplierBox" v-if="orderData.itsExtData">
      <accountPriceInfo :openTipsPage="openTipsPage" :orderData="orderData" ref="accountPriceInfo"></accountPriceInfo>
    </div>
    <div class="accountMain">
      <div class="piecesBox" v-if="orderData.orderExpensePieces">
        <div class="piecesMain" @click="showAccountInfo(item, orderData)" v-for="(item, index) in orderData.orderExpensePieces" :key="index">
          <accountExpensePiecesInfo :item="item" :goSurvayPage="goSurvayPage" :passengersList="passengersList" :goPassenger="goPassenger" :orderData="orderData"></accountExpensePiecesInfo>
        </div>
      </div>
      <!-- 山德士审批拒绝原因 -->
      <div class="adressBox" v-if="OwnerConfirmDescr && ['novartis'].includes($cookies.get('tenant'))">
        <div class="approve_reason">审批拒绝原因: {{ OwnerConfirmDescr }}</div>
      </div>
    </div>

    <tenantFoot v-if="!this.SmartStorage.get('viewOnly') && isLoade && !$route.query.isApproval" :orderExpensePieces="orderData.orderExpensePieces" ref="tenantFoot"></tenantFoot>
    <returnBox :retype="retype" @closeDialog="isRebackDialog = false" @quotePiece="PerformTask" :isloding="isloding" v-if="isRebackDialog"></returnBox>
    <approvalFoot ref="approvalFoot" :BtnClicks="BtnClicks" v-if="$route.query.isApproval"></approvalFoot>
    <carAccountInforPop ref="carAccountInforPop"></carAccountInforPop>
    <projectGrade v-if="$cookies.get('tenant') == 'msd'" ref="projectGrade" :GetExtraBtns="GetEOrder"></projectGrade>
    <MessageBox ref="tipsMsg" type="tips" :messageTxt="messageTxt" @on-ok="MessageBtn()"></MessageBox>
    <tipsPage ref="tipsPage"></tipsPage>
  </div>
</template>
<script>
import accountExpensePiecesInfo from '@/views/common/tenant/accountExpensePiecesInfo';
import accountPriceInfo from '@/views/common/tenant/accountPriceInfo';
import carAccountInforPop from '@/views/common/tenant/carAccountInforPop';
import tenantFoot from '@/views/common/tenantFoot';
import approvalFoot from '@/views/common/approvalFoot';
import returnBox from '@/views/common/returnBox';
import projectGrade from '@/views/common/projectGrade/projectGrade';
import filterEmoji from '@/views/controllers/filterEmoji.js';
import tipsPage from '@/views/common/tenant/tipsPage.vue';
export default {
  mixins: [filterEmoji],
  data() {
    return {
      isloding: true,
      isPassenger0: false,
      messageTxt: '',
      isRebackDialog: false,
      isScroll: false,
      isScroll2: false,
      isLoade: false,
      orderData: {},
      btnData: {},
      passengersList: [],
      isPerformTask: false,
      retype: '',
      carOrder: this.SmartStorage.get('carOrder'),
      OwnerConfirmDescr: '' //山德士审批拒绝原因
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.touchAccountBox);
  },
  mounted() {
    window.addEventListener('scroll', this.touchAccountBox);
  },
  created() {
    this.setRouteListeners('carAccountGoBack');
    this.$root.$eventHub.$on('carAccountGoBack', data => {
      this.carAccountGoBack();
      this.$root.$eventHub.$off('carAccountGoBack'); //解绑当前监听，否则下轮监听还会持续叠加
    });
    this.GetEOrder();
    //山德士审批拒绝原因
    if(['novartis'].includes(this.$cookies.get('tenant'))){
      let ItemData = this.SmartStorage.get('carOrder').ItemData;
      this.OwnerConfirmDescr = JSON.parse(ItemData).OwnerConfirmDescr;
    }
  },
  components: {
    accountExpensePiecesInfo,
    accountPriceInfo,
    tenantFoot,
    carAccountInforPop,
    projectGrade,
    approvalFoot,
    returnBox,
    tipsPage
  },
  methods: {
    openTipsPage() {
      this.$refs.tipsPage.openPop();
    },
    QueryPassenger() {
      let params = {
        BusinessId: this.SmartStorage.get('orderDetail').BusinessId
      };
      this.services.QueryPassenger(params).then(res => {
        if (res.success) {
          res.content.forEach(element => {
            element.passengers = this.StringExchangeEmoji(element.passengers);
            element.organization = this.StringExchangeEmoji(element.organization);
            element.department = this.StringExchangeEmoji(element.department);
            element.remark = this.StringExchangeEmoji(element.remark);
          });
          this.passengersList = res.content;
        }
      });
    },
    goPassenger(data) {
      console.log(234, data);
      this.$router.push({
        path: '/approvalPassenger',
        query: {
          businessId: data.expenseId,
          passengersList: data.orderPassenger
        }
      });
    },
    MessageBtn(flag) {
      this.isPerformTask && !flag
        ? this.PerformTask()
        : this.$router.push({
            path: '/approvalPassenger',
            query: {
              passengersList: this.passengersList
            }
          });
    },
    goDetailPage() {
      this.$refs.accountPriceInfo.goDetailPage();
    },
    async GetCurrentTaskId() {
      let params = {
        BusinessId: this.SmartStorage.get('orderDetail').BusinessId,
        ProcessId: this.SmartStorage.get('orderDetail').ProcessId,
        FlowCode: 'TMIS-DSM-Car-Approval'
      };
      this.services.GetCurrentTaskId(params).then(res => {
        if (res.success) {
          this.SmartStorage.set('TaskId', res.content);
        }
      });
    },
    async BtnClicks(btn) {
      await this.GetCurrentTaskId();
      this.btnData = btn;
      let data = this.SmartStorage.get('orderDetail');
      switch (btn.Processor) {
        case 'RebackQuote':
        case 'reback':
        case 'refused':
        case 'RefuseSettle':
          this.retype = btn.Processor;
          this.isRebackDialog = true;
          break;
        case 'AddDetail':
          // this.$emit("goAddDetail", data);
          break;
        case 'record':
          this.$router.push({
            path: '/approvalLog',
            query: {
              businessId: this.SmartStorage.get('orderDetail').BusinessId,
              ProcessId: this.SmartStorage.get('orderDetail').ProcessId
            }
          });
          break;
        case 'submit':
          this.isPerformTask = true;
          data.ActionId = btn.ActionId;
          this.SmartStorage.set('orderDetail', data);
          this.messageTxt = '确定' + btn.Txt + '吗？';
          this.$refs.tipsMsg.openPop();
          break;
        default:
          this.messageTxt = btn.Processor == 'refused' ? '否决后订单无法再次提交审批，叫车人将自行承担相关费用' : '确定' + btn.Txt + '审批吗？';
          this.messageTitle = btn.Processor == 'refused' ? '否决审批' : '提示';
          this.$refs.tipsMsg.openPop();
          this.isPerformTask = true;
          break;
      }
    },
    PerformTask(actDescr) {
      this.isloding = false;
      let params = {
        taskId: this.SmartStorage.get('TaskId'),
        // actionId: this.btnData.ActionId,
        ProcessId: this.SmartStorage.get('orderDetail').ProcessId,
        BusinessId: this.SmartStorage.get('orderDetail').BusinessId,
        actDescr: this.EmojiExchangeString(actDescr) || ''
      };
      if (this.$route.query.isApproval) {
        params.ActCode = this.btnData.Processor;
      } else {
        params.actionId = this.btnData.ActionId;
      }
      let _this = this;
      this.services.PerformTask(params).then(res => {
        if (res.success) {
          _this.$iToast('提交成功！');
          _this.carAccountGoBack();
        }
        this.isloding = true;
      });
    },
    goSurvayPage(data, btn) {
      console.log(btn);
      if (btn.status == 3) {
        this.$iToast('订单结束14天后无法填写满意度调查');
        return;
      } else {
        let item = {
          expenseId: data.expenseId,
          ItemId: '',
          ProposalId: this.SmartStorage.get('proposalId')
        };
        this.$refs.projectGrade.showPopup(item, btn);
      }
    },
    touchAccountBox(e) {
      let top = e.srcElement.scrollingElement.scrollTop;
      this.isScroll = top > 10 ? true : false;
      this.isScroll2 = top > 40 ? true : false;
    },
    showAccountInfo(item, data) {
      console.log('item,data000000000', item, data);
      this.$refs.carAccountInforPop.showPopup(item, data);
    },
    carAccountGoBack() {
      this.removeRouteListeners('carAccountGoBack');
      this.$route.query.isApproval ? this.$router.back() : this.$route.query.isFromeAll ? this.$router.push('/allCarOrder') : this.$router.push('/cityIndex');
      // this.$router.back();
    },
    GetEOrder() {
      this.isLoade = false;
      let param = {
        filters: {
          orderId: this.SmartStorage.get('carOrder').orderId
        }
      };
      let _this = this;
      this.services.GetEOrder(param).then(res => {
        if (res.success) {
          res.content.orderExpensePieces &&
            res.content.orderExpensePieces.forEach(ele => {
              if (ele.orderPassenger.length == 0 && ['msd'].includes(this.$cookies.get('tenant'))) {
                ele.itsExpenseData?.passengersList?.forEach(pa => {
                  pa.passengers = pa.name;
                  pa.department = pa.hospital;
                });
                ele.orderPassenger = ele.itsExpenseData.passengersList;
              }
              ele.orderPassenger?.forEach(element => {
                element.passengers = this.StringExchangeEmoji(element.passengers);
                element.organization = this.StringExchangeEmoji(element.organization);
                element.department = this.StringExchangeEmoji(element.department);
                element.remark = this.StringExchangeEmoji(element.remark);
              });
              ele.isShow = false;
            });
          _this.SmartStorage.set('taskId', res.content.taskId);
          _this.orderData = res.content;
          _this.isLoade = true;
          if (res.content.status == 100 && !_this.$route.query.isApproval && !_this.SmartStorage.get('viewOnly') && _this.$cookies.get('tenant') == 'msd') {
            _this.GetExtraBtns();
          }
        }
      });
    },
    GetExtraBtns() {
      let _this = this;
      let params = this.orderData.orderExpensePieces.map(data => data.expenseId);
      this.services.GetExtraBtns(params).then(function(res) {
        if (res.success) {
          for (let i = 0; i < _this.orderData.orderExpensePieces.length; i++) {
            if (params.some(item => item == res.content[i].itemId)) {
              let btn = {
                Txt: '满意度调查',
                Processor: 'goSurvayPage',
                status: res.content[i].status
              };
              _this.$cookies.get('tenant') == 'msd' && _this.orderData.orderExpensePieces[i].buttons.push(btn);
            } else {
              console.log(res.content[i]);
            }
          }
          console.log(_this.dataList);
        }
      });
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/tenant/carAccount.scss'
</style>
