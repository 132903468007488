<template>
  <div class="CarInfoTrack">
    <iHeader
        backType="blank"
        :backEvent="true"
        @back-event="carInfoTrackBack()"
        :back="true"
        svgColor="'#13161B"
      >
    <div slot="headText">
        <span class="head-txt">
          行车轨迹
        </span>
    </div>
    </iHeader>
    <div class="img_list" v-if="imgList.length>0">
        <img :src="item.url" alt=""  v-for="(item,index) in imgList" :key="item+index">
    </div>
    <div v-else class="no_data">
      暂无数据
    </div>
    <!-- <smart-upload :showViewer="true" :platform="1" :bucketAlias="'SmartEvent'" :maxUpload="100"  :filterData="filterData" :autoMapping="true" :readOnly="true" >
    </smart-upload>  -->

  </div> 
</template>
<script>
// import { smartUpload } from "@/views/controllers/upload";
export default {
  name: "CarInfoTrack",
  data(){
    return {
      imgList:[],
      // imgList: [
      //   {
      //     fileType: ".png",
      //     fileName: "OCR-1620788101385.png",
      //     fileKey: "2f7fa851-776d-4795-ab8a-d04e104dc2cb",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/2f7fa851-776d-4795-ab8a-d04e104dc2cb"
      //   },
      //   {
      //     fileType: ".jpg",
      //     fileName: "20210601_135732.jpg",
      //     fileKey: "8ec27642-cacc-4e49-8dcb-c43ffc52131b",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/8ec27642-cacc-4e49-8dcb-c43ffc52131b"
      //   },
      //   {
      //     fileType: ".jpg",
      //     fileName: "Screenshot_2021-05-13-14-49-52-390_cn.exc.pfizer.jpg",
      //     fileKey: "9a03be97-ff95-42ca-92f0-3e0bf7f48e2d",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/9a03be97-ff95-42ca-92f0-3e0bf7f48e2d"
      //   },
      //   {
      //     fileType: ".png",
      //     fileName: "OCR-1622527493783.png",
      //     fileKey: "dc1a26ad-a05c-426c-9ed4-bf2a2957dd7b",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/dc1a26ad-a05c-426c-9ed4-bf2a2957dd7b"
      //   },
      //   {
      //     fileType: ".png",
      //     fileName: "20db323b-56f8-4e1e-9339-cf57fc10ae53.png",
      //     fileKey: "dcf957e3-df01-4e41-a331-acb3fdf4f331",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/dcf957e3-df01-4e41-a331-acb3fdf4f331"
      //   },
      //   {
      //     fileType: ".png",
      //     fileName: "OCR-1622527065693.png",
      //     fileKey: "fddeaaa2-9e3b-4c91-b5dc-ffcbc81d27d3",
      //     url:
      //       "https://smarteclouddev.oss-cn-shanghai.aliyuncs.com/fddeaaa2-9e3b-4c91-b5dc-ffcbc81d27d3"
      //   }
      // ]
    }
  },
  props: {
    orderData: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  components: {
    // smartUpload,
  },
  mounted() {
    let params={
      filterData:{
        processId: this.orderData.eventId,
        catalog: "行车轨迹",
        supplierId: this.orderData.supplierId,
        relatedId: this.item.expenseId
      }
    }
    this.services.getOssQuery(params).then((res) => {
      this.imgList=res.content; 
      });
  },
  methods: {
    carInfoTrackBack(){
      this.$emit('carInfoTrackEvent',true)
    },
    
    }
  }

</script>
<style scoped="scoped" lang="scss">
.CarInfoTrack{
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0; 
  z-index: 999;
  .img_list{
    padding: .15rem;
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    justify-content: space-between;
    img{
      width: 45%;
      height: 1.5rem;
      margin-right: .1rem;
      margin-bottom: .1rem;
    }
  }
  .no_data{
    margin-top: 2rem;
  }
}
</style>
