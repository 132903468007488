<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="accountBox carAccountInforPopMain">
      <div class="headerBox">
        <div class="topBack"></div>
        <div class="header_title">
          <div class="closeBtn" @click="carAccountback()">
            <svg class="icon" aria-hidden="true" v-if="!device">
              <use xlink:href="#iconclose" />
            </svg>
            <span v-if="device" class="f16">
              返回
            </span>
          </div>
          <div>结算明细</div>
          <div></div>
        </div>
        <timeAddressPiece :item="item" :isShowDetail="false" :orderData="orderData"></timeAddressPiece>
      </div>
      <div class="cateGList accountBox" v-if="item.itsExpenseData">
        <div class="carModusBox">
          <div class="address_info">
            <div class="flex1">车型</div>
            <div>{{ item.itsExpenseData.carType }}</div>
          </div>
          <div class="address_info">
            <div class="flex1">车牌号</div>
            <div>{{ item.itsExpenseData.licensePlate }}</div>
          </div>
          <div class="address_info">
            <div class="flex1">行程数</div>
            <div>{{ item.itsExpenseData.kilometers }} km</div>
          </div>
          <div class="address_info">
            <div class="flex1">用车形式</div>
            <div>{{ item.itsExpenseData.carModus }}</div>
          </div>
          <div class="address_info">
            <div class="flex1">
              <i class="greenBc"></i>
              出发地
            </div>
            <div>
              {{ item.itsExpenseData && item.itsExpenseData.departure }}
            </div>
          </div>
          <div class="address_info">
            <div class="flex1">
              <i class="orangeBc"></i>
              目的地
            </div>
            <div>
              {{ item.itsExpenseData && item.itsExpenseData.destination }}
            </div>
          </div>
          <!-- <div class="address_info">
            <div class="flex1">超预算说明</div>
            <div>{{ item.itsExpenseData.budgetDescr || "暂无超预算说明" }}</div>
          </div> -->
          <div class="address_info">
            <div class="flex1">备注信息</div>
            <div>{{ item.itsExpenseData.descr || '暂无备注' }}</div>
          </div>
          <div class="address_info">
            <div></div>
            <div class="priceBox">¥{{ item.amount }}</div>
          </div>
          <div class="address_info">
            <div class="flex1">乘车人员</div>
            <div>{{ item.itsExpenseData.passengersList.length }}</div>
          </div>
        </div>
        <div class="passagerBox ">
          <passagerList :item="item" :number="item.itsExpenseData.passengersList.length"></passagerList>
        </div>
        <!-- 展示司机信息详情 -->
        <!-- <template  v-if="driverData">
        <div class="currentDriver">
          <div>司机信息</div>
          <div>{{ driverData.name}}({{driverData.phone}})</div>
        </div>
        <div class="currentDriver">
          <div>车辆信息</div>
          <div>{{driverData.carModel}}{{driverData.licensePlate}}</div>
        </div>
      </template> -->
        <div class="mapBox" @click="showCarInfoTrack = true" v-if="['msd'].includes($cookies.get('tenant'))">
          <div class="flex">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-hangcheguiji" />
            </svg>
            <span>查看行车轨迹</span>
          </div>
          <div class="right_btn">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jinru" />
            </svg>
          </div>
        </div>
        <div v-if="showCarInfoTrack">
          <carInfoTrack :item="item" :orderData="orderData" @carInfoTrackEvent="showCarInfoTrack = false"></carInfoTrack>
        </div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import passagerList from '@/views/common/tenant/passagerList';
import timeAddressPiece from '@/views/common/tenant/timeAddressPiece';
import carInfoTrack from '@/views/common/tenant/carInfoTrack';
import { device } from '@/views/utils/common';
export default {
  data() {
    return {
      isShowPopup: false,
      isShowDetail: false, //是否展示地点金额等信息
      item: {},
      filterData: {},
      showTrack: false, //是否显示行车轨迹
      showCarInfoTrack: false,
      orderData: {},
      driverData:{}
    };
  },
  components: {
    passagerList,
    timeAddressPiece,
    carInfoTrack
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners('carAccountback'); //保存当前路由返回事件记录
        this.$root.$eventHub.$on('carAccountback', data => {
          this.carAccountback();
          this.$root.$eventHub.$off('carAccountback');
        });
      } else {
        this.removeRouteListeners('carAccountback'); //返回删除记录
      }
    }
  },
  created() {
    this.device = device();
   
  },
  methods: {
    showPopup(item, data) {
      this.item = item;
      this.orderData = data;
      // this.$refs.pop.changePop();
      console.log('555', item, data);
      this.isShowPopup = true;
      // this.getCurrentDriver(data);
    },
    carAccountback() {
      this.isShowPopup = false;
    },
    getCurrentDriver(data) {
        let params = {
          orderId:data.orderId
        };
        this.services.currentDrivers(params).then(res => {
          console.log('res===', res);
          this.driverData=res.content;
          // console.log('driverData',this.driverData);
        });

    }
  }
};
</script>

<style lang="scss">
@import '@/assets/tenant/carAccount.scss';
</style>
