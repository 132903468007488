<template>
  <div>
    <div v-if="btnList.length > 0" class="footBtns footBox">
      <a
        v-for="(btn, index) in btnList"
        :key="index"
        :class="['btnList', btn.Processor, 'btns' + btnList.length]"
        @click="BtnClicks(btn)"
        >{{ btn.Txt }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props:["BtnClicks"],
  data() {
    return {
      isChoseDialog: false,
      btnList: [],
      btnData: {},
    };
  },
  components: {},
  computed: {},
  created() {
    this.btnList = this.SmartStorage.get("orderDetail")
      ? this.SmartStorage.get("orderDetail").ItsButtons
      : [];
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/common.scss'
</style>