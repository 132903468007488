<template>
  <div>
    <div v-if="btnList.length > 0" class="footBtns footBox" :class="btnList.length >= 3 ? 'extra_style' : ''">
      <a v-for="(btn, index) in btnList" :key="index" :class="['btnList', btn.actCode, 'btns' + btnList.length]" @click="BtnClicks(btn)">{{ btn.actName }}</a>
    </div>
    <MessageBox ref="msg" type="tips" :messageTxt="messageTxt" @on-ok="PerformTask"></MessageBox>
    <refuseBox ref="refuseBox" :PerformTask="PerformTask"></refuseBox>
  </div>
</template>
<script>
import refuseBox from '@/views/common/refuseBox';
export default {
  props: ['orderExpensePieces'],
  data() {
    return {
      rebackDescr: '',
      btnList: [],
      messageTxt: '',
      choseBtn: {},
      rebackTitle:'退回'
    };
  },
  mounted() {
    this.QueryTaskActions();
  },
  components: {
    refuseBox
  },
  methods: {
    cancelMessage() {
      this.$refs.msg.openPop();
    },
    QueryTaskActions() {
      let param;
      if (this.SmartStorage.get('carOrder').Status == 100 && this.SmartStorage.get('tenant_code') == 'novartis') {
        this.rebackTitle='拒绝';
        param = {
          processId: this.SmartStorage.get('carOrder')?.ItemId,
          taskId: this.SmartStorage.get('carOrder')?.TaskId,
          userId: this.SmartStorage.get('userId')
        };
      } else {
        param = {
          displayType: 'detail',
          taskId: this.SmartStorage.get('taskId')
        };
      }

      let _this = this;
      this.services.QueryTaskActions(param).then(res => {
        if (res.success) {
          _this.btnList = res.content;
        }
      });
    },
    PerformTask(isDbConfirm = false) {
      console.log('choseBtn==',this.choseBtn);
      let param = {
        actionId: this.choseBtn.actionId
      };
      if(this.choseBtn.actCode!='approve'){
        param.taskId= this.SmartStorage.get("taskId");
        param.actDescr= this.rebackDescr || ""
      }else{
        param.TaskData={
          Descr:this.rebackDescr
        }
        param.taskId= this.SmartStorage.get('carOrder')?.TaskId;
      }
      if (isDbConfirm) {
        param.isDbConfirm = true;
      }
      let _this = this;
      this.services.PerformTask(param).then(res => {
        if (res.success) {
          if (res.code == 3) {
            res.content
              ? this.Reconfirm(res.msg)
              : this.$iMessageBox.render({
                  btnType: 'single',
                  singleTxt: '确定',
                  messageTitle: '提示',
                  messageTxt: res.msg,
                  onSingle: function() {
                    console.log(res.msg);
                  }.bind(this)
                });
          } else {
            _this.$iToast('操作成功');
            _this.$route.query.isApproval ? _this.$router.back() : _this.$route.query.isFromeAll ? _this.$router.push('/allCarOrder') : _this.$router.push('/cityIndex');
            // this.$router.back();
            // _this.$router.push("/cityIndex");
          }
        }
      });
    },
    Reconfirm(msg) {
      let _this = this;
      this.$iMessageBox.render({
        messageTitle: '提示',
        messageTxt: msg,
        onConfirm: function() {
          _this.PerformTask(true);
        }.bind(this)
      });
    },
    BtnClicks(data) {
      this.choseBtn = data;
      if (this.$cookies.get('tenant') == 'msd' && data.actCode == 'ConfirmSettle') {
        let pasengerReg = /^((?!教授|老师|医生|主任).)*$/;
        let flag = false;
        this.orderExpensePieces.forEach(element => {
          console.log(element);
          if (
            !element.orderPassenger.every((item, index, arr) => {
              return pasengerReg.test(item.name);
            })
          ) {
            flag = true;
          }
        });
        if (flag) {
          this.$iToast('乘车人姓名中不能包含“教授、老师、医生、主任”等，请录入乘车人全名');
          return false;
        }
      }
      switch (data.actCode) {
        case 'RebackQuote':
        case 'reback':
          this.$refs.refuseBox.showPopup();
          break;
        case 'RefuseSettle':
          this.$refs.refuseBox.showPopup();
          break;
        case 'Reject':
        this.$refs.refuseBox.showPopup('',this.rebackTitle);
        break;   
        default:
          this.messageTxt = '确定' + data.actName + '吗？';
          this.$refs.msg.openPop();
          break;
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/common.scss'
</style>
