<template>
  <Popup ref="pop">
    <template v-slot:popContent>
      <div :style="pageStyle" class="invoiceMsgContent">
        <div class="editOrderBox">
          <iHeader
            backType="blank"
            :headStyle="
              ['msd', 'dsm'].includes($cookies.get('tenant'))
                ? {
                    background: '#fff',
                    color: '#13161B',
                  }
                : {
                    background: 'var(--themeColor)',
                    color: '#fff',
                  }
            "
            :backEvent="true"
            @back-event="openPop()"
            :back="true"
          >
            <div slot="headText">
              <span class="head-txt">
                {{
                ["pfizer"].includes($cookies.get("tenant"))
                  ? "租车需求"
                  : "城市车队需求"
              }}
              </span>
            </div>
          </iHeader>
          <!-- <div class="publicHead">
            <p>
              {{
                ["pfizer"].includes($cookies.get("tenant"))
                  ? "租车需求"
                  : "地方车队需求"
              }}
            </p>
            <span>
              注意事项
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconright" />
              </svg>
            </span>
          </div> -->
          <div class="editOrderContent">
            <div>
              <Tips titile="注意事项" :tips="tips" :val="'val'"></Tips>
              <div class="imgBoxContent">
                <p class="title">费用说明</p>
                <p class="titleShow">车队行程报价说明</p>

                <img
                  :src="
                    $cookies.get('tenant') == 'msd'
                      ? 'https://spkg.smartmice.cn/resources/images/icar/priceDescription.jpg'
                      : 'https://spkg.smartmice.cn/resources/images/icar/priceDescription_pfizer.jpg'
                  "
                  preview="1"
                />
                <p class="titleShow">车队订单取消违约金说明</p>
                <img
                  :src="
                    $cookies.get('tenant') == 'msd'
                      ? 'https://spkg.smartmice.cn/resources/images/icar/canselDescription.jpg'
                      : 'https://spkg.smartmice.cn/resources/images/icar/canselDescription_pfizer.jpg'
                  "
                  preview="1"
                />
              </div>

              <!-- <Tips
                titile="费用说明"
                :tips="tips2"
                :val="'val'"
                color="#fec22c"
              ></Tips> -->
              <div class="backBtn" @click="openPop">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconright" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>
<script>
import Vue from "vue";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
let option = { bgOpacity: 0.3 };
Vue.use(preview, option);
export default {
  data() {
    return {
      tips2: [
        {
          val:
            "市内点到点单程/火车站接送单程：限20公里，包含路桥费和停车费。机场接送单程：限40公里，包含路桥费和停车费；接机因航班延误可免费等待。",
        },
        {
          val:
            "半天包车：限4小时50公里，不含路桥费和停车费；全天包车：限8小时100公里，不含路桥费和停车费。",
        },
        {
          val:
            "市内点对点接送/火车站接送/机场接送/半天包车/全天包车的用车，司机在与客户约定的上车时间前提前到达上车点，如客户未在约定的时间内上车，则按照不同车型的超时费收取超时等候费用。司机免费等待半小时，超过半小时不足1小时的按1小时计费。",
        },
        {
          val:
            "跨城市接送：可提供跨城市用车服务，根据用户提供的用车行程单独提供报价。",
        },
        {
          val:
            "市内单程/火车站接送单程/机场接送单程服务中增加临时上下车点，如起始上车地点至最终下车地点的里程公里数累积不超过约定公里数，则不收取中途停靠费用。超出约定公里数，则按不同车型超公里收费，不单独收取增加站点费用。",
        },
        {
          val: `7座（含）以下小车4小时内取消收取一半车费，2小时内取消收取全额车费。
            <br />4小时之前可以免费取消。`,
        },
        {
          val:
            "7座以上车型，在原订用车时间前24小时内取消收取全额车费。在原订用车时间前24小时外可免费取消。",
        },
        {
          val:
            "如因汽车公司的原因（如人员问题、车辆故障等）导致客户无法用车的：针对市区内/接送机/接送火车站/半天/包天的用车，将在半小时内安排替代车辆到位，或协助客户打车并由汽车公司承担打车费用；针对跨城市用车，将让客户以最方便和快捷的交通方式到达目的地，并承担由此发生的实际交通费用。如因不可抗力因素（如遇自然灾害等）造成服务中止，则应配合客户协商解决方案。",
        },
      ],
      tips: [
        {
          val: ["pfizer"].includes(this.$cookies.get("tenant"))
            ? "租车需求"
            : "城市车队需求" + "，请提前2天预定",
        },
        {
          val:
            "跨城市用车安排需合理且符合公司规定，避免提供或支付铺张奢侈的交通服务，如包车等用车安排",
        },
      ],
    };
  },
  created() {
    // let msdtip = {
    //   val: `要使用以下提名车队，请选择易来回车队，并在备注中标明提 名车队名称：
    //                 <br />天津市宇轩汽车租赁有限公司
    //                 <br />济南宏旅汽车租赁有限公司
    //                 <br />武汉诺捷汽车租赁有限公司
    //                 <br />成都迈德科商务服务有限公司
    //                 <br />南京顺驰捷汽车租赁有限公司
    //                 <br />广州兴缘汽车租赁有限公司`,
    // };
    // this.$cookies.get("tenant") == "msd" && this.tips.push(msdtip);
    this.setStyle();
    this.$previewRefresh();
    this.$preview.on("close", () => {
      this.isShow = false;
    });
  },
  mounted() {
    // this.$previewRefresh();
  },
  methods: {
    //设置全屏
    setStyle() {
      let height = window.innerHeight + "px";
      this.pageStyle = {
        height: height,
        background: "#fff",
      };
    },
    //开启\关闭弹框
    openPop() {
      this.$refs.pop.changePop();
    },
    onOk() {
      //                this.$emit('setDate', date);
      this.openPop(this.edit);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/tenant/editOrder.scss";
.pswp--open {
  z-index: 9999999 !important;
}
</style>
