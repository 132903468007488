<template>
  <div>
    <div v-for="(passager,pIndex) in item.itsExpenseData.passengersList" :key="pIndex" class="poPassage">
      <template v-if="pIndex<number">
        <div>{{passager.name}}</div>
        <p>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icongps" />
          </svg>
          {{passager.hospital}}
        </p>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    },
    number:{
      type:Number
    }
  }
};
</script>
<style lang="sass" scoped>

</style>